import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Herobg from "../Images/Bg/Hero-bg.png";
import IconFb from "../Images/Icons/icon-facebook-b.png";
import IconInstagram from "../Images/Icons/icon-instagram-b.png";
import IconYoutube from "../Images/Icons/icon-youtube-b.png";
import IconX from "../Images/Icons/icon-x-b.png";
import icnocross from "../Images/Icons/icon-cross.png";
import icnobuy from "../Images/Icons/icon-buy.png";
import icnoreveive from "../Images/Icons/icon-receive.png";
import icnoswap from "../Images/Icons/icon-swap.png";
import Heromobile1 from "../Images/Mobiles/hero-1.png";
import Heromobile2 from "../Images/Mobiles/hero-2.png";
import Heromobile3 from "../Images/Mobiles/hero-3.png";
import Heromobile4 from "../Images/Mobiles/hero-4.png";
import { AppStoreButton } from "./ButtonList";
function InnerHeader() {
  return (
    <>
      <Container className="inner-header" fluid>
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${Herobg})` }}
        />
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12">
                <h1>Welcome to FirstDex</h1>
                <p>
                  At FirstDex, we prioritize your privacy and are committed to
                  providing a secure, user-friendly platform for managing
                  cryptocurrencies. This Privacy Policy explains how we collect,
                  use, and protect your data while facilitating seamless
                  cross-chain and fiat-to-crypto transactions. By using
                  FirstDex, you agree to the terms outlined in this policy.
                </p>
                <AppStoreButton />
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    </>
  );
}
export default InnerHeader;
