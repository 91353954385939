import { Link } from "react-router-dom";
import googleplay from "../Images/Icons/icon-google-play.png";
import { Button, Image } from "react-bootstrap";
export const Btnlist = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling effect
    });
  };

  return (
    <ul className="btn-list">
      <li>
        <Button className="reg-btn fill" onClick={handleScrollToTop}>
          Download
        </Button>
      </li>
      {/* <li>
  <Link href="#">Learn More</Link>
</li> */}
    </ul>
  );
};

export const BtnlistWhite = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling effect
    });
  };

  return (
    <ul className="btn-list">
      <li>
        <Button className="white" onClick={handleScrollToTop}>
          Download
        </Button>
      </li>
      {/* <li>
  <Link href="#">Learn More</Link>
</li> */}
    </ul>
  );
};

export const SimpleLink = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling effect
    });
  };

  return (
    <Button className="simple-btn" onClick={handleScrollToTop}>
      Get Started <i className="fa fa-arrow-right"></i>
    </Button>
  );
};

export const AppStoreButton = () => (
  <>
    <ul className="app-store-list">
      <li>
        <a
          id="googleplay-btn"
          className="apple-store-btn"
          href="https://play.google.com/store/apps/details?id=com.sorrowplace.app"
        >
          <Image src={googleplay} alt="Google Play" />
          <div>
            <span>Get In On</span>
            <h6>Google Play</h6>
          </div>
        </a>
      </li>
      <li>
        <a
          id="appstore-btn"
          className="apple-store-btn"
          href="https://apps.apple.com/pk/app/sorrow-place/id6469109994"
        >
          <i className="fa fa-apple"></i>
          <div>
            <span>Download on the</span>
            <h6>App Store</h6>
          </div>
        </a>
      </li>
    </ul>
  </>
);
