import React from "react";
import Container from "react-bootstrap/Container";
import { Image, Nav, Navbar } from "react-bootstrap";
import { Btnlist } from "./ButtonList";
import Logo from "../Images/Logo/logo.svg";

function NavBar() {
  return (
    <Navbar className="custom-navbar" expand="lg">
      <Container fluid>
        {/* Left: Logo */}
        <Navbar.Brand href="/" className="navbar-logo">
          <Image src={Logo} alt="Company Logo" />
        </Navbar.Brand>

        {/* Center: Nav Links */}
        <Navbar.Toggle
          aria-controls="navbarScroll"
          aria-label="Toggle navigation"
        />
        <Navbar.Collapse id="navbarScroll" className="navbar-links">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/#Header">About</Nav.Link>
            <Nav.Link href="/">Coins</Nav.Link>
            <Nav.Link href="/#Help-center">Help Centre</Nav.Link>
            <Nav.Link href="/">Tutorial Videos</Nav.Link>
          </Nav>
        </Navbar.Collapse>

        {/* Right: Button List */}

        <Btnlist />
      </Container>
    </Navbar>
  );
}

export default NavBar;
