import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Herobg from "../Images/Bg/Hero-bg.png";
import IconFb from "../Images/Icons/icon-facebook-b.png";
import IconInstagram from "../Images/Icons/icon-instagram-b.png";
import IconYoutube from "../Images/Icons/icon-youtube-b.png";
import IconX from "../Images/Icons/icon-x-b.png";
import icnocross from "../Images/Icons/icon-cross.png";
import icnobuy from "../Images/Icons/icon-buy.png";
import icnoreveive from "../Images/Icons/icon-receive.png";
import icnoswap from "../Images/Icons/icon-swap.png";
import Heromobile1 from "../Images/Mobiles/hero-1.png";
import Heromobile2 from "../Images/Mobiles/hero-2.png";
import Heromobile3 from "../Images/Mobiles/hero-3.png";
import Heromobile4 from "../Images/Mobiles/hero-4.png";
import { AppStoreButton } from "./ButtonList";
function Header() {
  return (
    <>
      <Container className="header" fluid>
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${Herobg})` }}
        />
        <div className="header-inner-pnl">
          <div className="text-panel">
            <h1>
              First Decentralized <br />
              Exchange in India
            </h1>
            <p className="mb-1">
              FirstDex stands as a leading decentralized exchange, offering more
              than 300 tokens across multiple blockchains to choose from
              providing unparalleled variety for traders and investors.
            </p>
            <p>
              Dive into a dynamic ecosystem where innovation meets diversity,
              empowering users to explore the crypto market like never before.
            </p>
            <AppStoreButton />
          </div>
          <div className="img-panel">
            <Image src={Heromobile1} alt="Hero Mobile" />
            <Image src={Heromobile2} alt="Hero Mobile" />
            <Image src={Heromobile3} alt="Hero Mobile" />
          </div>
        </div>

        <p className="floating-text">
          <b>FirstDex—India’s</b> First Trusted Wallet That Simplifies Crypto
        </p>
        {/* SMall Mobile Panel */}
        <div className="small-mobile-pnl">
          <div className="small-mobile-pnl-inner">
            <Image src={Heromobile4} alt="Hero Mobile" />
            <Link className="crypto-btn" href="/">
              <Image src={icnocross} alt="Cross" />
              Cross
            </Link>
            <Link className="crypto-btn" href="/">
              <Image src={icnobuy} alt="Buy" />
              Buy
            </Link>
            <Link className="crypto-btn" href="/">
              <Image src={icnoreveive} alt="Receive" />
              Receive
            </Link>
            <Link className="crypto-btn" href="/">
              <Image src={icnoswap} alt="Swap" />
              Swap
            </Link>
          </div>
        </div>
        {/* SMall Mobile Panel */}

        {/* Social Media List */}
        <div className="header-social-cntnr">
          <ul className="social-media-list">
            <li>
              <Link target="_blank" to="https://www.facebook.com/">
                <Image src={IconFb} alt="Facebook" />
              </Link>
            </li>
            <li>
              <Link target="_blank" to="https://www.instagram.com/">
                <Image src={IconInstagram} alt="Instagram" />
              </Link>
            </li>
            <li>
              <Link target="_blank" to="https://www.youtube.com/">
                <Image src={IconYoutube} alt="Youtbe" />
              </Link>
            </li>
            <li>
              <Link target="_blank" to="https://x.com/">
                <Image src={IconX} alt="X" />
              </Link>
            </li>
          </ul>
        </div>
        {/* Social Media List */}
      </Container>
    </>
  );
}
export default Header;
