import React from "react";
import { Image } from "react-bootstrap";
import getstarted from "../Images/Mobiles/get-started.png";
import { AppStoreButton } from "./ButtonList";
function GetStarted() {
  return (
    <>
      <div className="get-started">
        <div className="txt-pnl">
          <h3>
            Start your hassle-free crypto journey with <b>FirstDex</b> today!
          </h3>
          <p>
            Experience secure transactions, seamless swaps, and compliant
            solutions—all in one powerful wallet.
          </p>
          <AppStoreButton />
          <div className="spacer-40"/>
        </div>
        <div className="img-pnl">
          <Image src={getstarted} alt="Get Started" />
        </div>
      </div>
    </>
  );
}
export default GetStarted;
