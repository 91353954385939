import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Scss/App.scss";
import { Route, Routes } from "react-router-dom";
import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsConditions from "./Pages/TermsConditions";
function NotFound() {
  return (
    <div className="pagenotfound">
      <h1>404 - Page Not Found</h1>
    </div>
  );
}
function Router() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route exact path="/TermsConditions" element={<TermsConditions />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default Router;
