import React, { useEffect, useState } from "react";
import GetinTouch from "../Components/GetinTouch";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import mobile1 from "../Images/Mobiles/Mobile.png";
import mobile2 from "../Images/Mobiles/Featured.png";
import mobile3 from "../Images/Mobiles/Swap-Coins.png";
import mobile4 from "../Images/Mobiles/Buy-Coins.png";
import mobile5 from "../Images/Mobiles/india-first.png";
import TileL from "../Images/Bg/Bg-Tiles-L.png";
import TileLS from "../Images/Bg/Bg-Tiles-L-S.png";
import TileR from "../Images/Bg/Bg-Tiles-R.png";
import TileRS from "../Images/Bg/Bg-Tiles-R-S.png";
import circlebg from "../Images/Bg/bg-mask.png";
import circlebgb from "../Images/Bg/bg-mask-b.png";
import Mask from "../Images/Bg/bg-mask-1.png";
import iconsearch from "../Images/Icons/icon-search.png";
import intro1 from "../Images/Steps/Step-1.png";
import intro2 from "../Images/Steps/Step-2.png";
import intro3 from "../Images/Steps/Step-3.png";
import intro4 from "../Images/Steps/Step-4.png";
import {
  AppStoreButton,
  Btnlist,
  BtnlistWhite,
  SimpleLink,
} from "../Components/ButtonList";
import GetStarted from "../Components/GetStarted";
import Header from "../Components/Header";
function Home() {
  const [animatedElements, setAnimatedElements] = useState([]);

  // Function to check if an element is in the viewport
  function isElementInViewport(elem) {
    const scroll = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const elemTop = elem.getBoundingClientRect().top + scroll;

    return elemTop - scroll < windowHeight;
  }

  // Function to add the "anime" class to sections in the viewport
  function animateSections() {
    const elementsToAnimate = document.querySelectorAll(".scroll-anime");
    const elementsInViewport = [];

    elementsToAnimate.forEach((elem) => {
      if (isElementInViewport(elem)) {
        elem.classList.add("anime");
        elementsInViewport.push(elem);
      }
    });

    setAnimatedElements(elementsInViewport);
  }

  useEffect(() => {
    // Initial check on page load
    animateSections();

    // Add scroll event listener
    window.addEventListener("scroll", animateSections);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("scroll", animateSections);
    };
  }, []);

  // Define the list of steps as an array of objects
  const steps = [
    {
      number: "Step 1",
      description: "Create a self custody wallet (takes one min)",
    },
    {
      number: "Step 2",
      description: "Complete Kyc and bank account (takes two minutes)",
    },
    {
      number: "Step 3",
      description: "Deposit INR (takes one min)",
    },
    {
      number: "Step 4",
      description: "Start buying on blockchain",
    },
  ];
  const intro = [
    {
      img: intro1,
    },
    {
      img: intro2,
    },
    {
      img: intro3,
    },
    {
      img: intro4,
    },
  ];
  // Initialize the active step index in state
  const [activeStep, setActiveStep] = useState(0);
  // Function to update the active step every specified interval
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((prevStep) => (prevStep + 1) % steps.length);
    }, 4000); // Change the interval as needed (3000 milliseconds = 3 seconds)
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);
  // Initialize the active step index in state

  const [activeIntro, setActiveIntro] = useState(0);
  // Function to update the active step every specified interval
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIntro((prevIntro) => (prevIntro + 1) % intro.length);
    }, 4000); // Change the interval as needed (3000 milliseconds = 3 seconds)
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <>
      <main  id="Header" className="home-main">
        {/* Header */}
        <Header />
        {/* Header */}

        {/* Mobile Section */}
        <Container fluid className="scroll-anime lf-anime mobile-post tile-bg">
          <div
            className="bg-layer"
            style={{ backgroundImage: `url(${TileL}), url(${TileRS})` }}
          />
          <Row>
            <Container>
              <Row>
                <Col
                  xl={{ span: 3, order: 1 }}
                  lg={{ span: 4, order: 1 }}
                  md={{ span: 4, order: 1 }}
                  sm={{ span: 6, order: 2 }}
                  xs={{ span: 6, order: 2 }}
                >
                  <Image src={mobile1} alt="Mobile" />
                </Col>
                <Col
                  xl={{ span: 8, order: 2, offset: 1 }}
                  lg={{ span: 7, order: 2, offset: 1 }}
                  md={{ span: 8, order: 2, offset: 0 }}
                  sm={{ span: 12, order: 1, offset: 0 }}
                  xs={{ span: 12, order: 1, offset: 0 }}
                  className="d-flex flex-column  justify-content-center mb-4"
                >
                  <h2>
                    Largest Dex with more than 300 tokens to choose from to buy{" "}
                  </h2>
                  <p>
                    Access all major tokens from leading blockchains like
                    Ethereum, Solana, and Binance Smart Chain (BNB) for seamless
                    buying and selling within seconds.
                  </p>
                  <SimpleLink />
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
        {/* Mobile Section */}

        {/* Featured Panel */}
        <Container fluid className="scroll-anime featured-panel">
          <div
            className="bg-layer"
            style={{ backgroundImage: `url(${circlebgb})` }}
          />
          <Row>
            <Container>
              <Row>
                <Col
                  xl="8"
                  lg="7"
                  md="8"
                  sm="12"
                  xs="12"
                  className="d-flex flex-column  justify-content-center"
                >
                  <h4 className="font-weight-normal">
                    Create an account and buy crypto in less than 5 minutes
                  </h4>
                  <div className="spacer-10" />
                  <ul id="steps" className="about-list scroll-anime step-nav">
                    {steps.map((step, index) => (
                      <li
                        key={index}
                        className={`step ${
                          index === activeStep ? "active" : ""
                        }`}
                      >
                        <p>
                          <b>{step.number} :</b>
                          {step.description}
                        </p>
                      </li>
                    ))}
                  </ul>
                  <div className="spacer-40" />
                  <Btnlist />
                  <div className="spacer-40" />
                </Col>
                <Col
                  xl={{ span: 3, offset: 1 }}
                  lg={{ span: 4, offset: 1 }}
                  md={{ span: 4, offset: 0 }}
                  sm={{ span: 6, offset: 0 }}
                  xs={{ span: 12, offset: 0 }}
                >
                  <ul id="intro" className="intro-list scroll-anime step-nav">
                    {intro.map((intro, index) => (
                      <li
                        key={index}
                        className={`intro ${
                          index === activeIntro ? "active" : ""
                        }`}
                      >
                        <div className="img-pnl">
                          <img src={intro.img} alt={`slide ${index}`} />
                        </div>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
        {/* Featured Panel */}

        {/* Mobile Section */}
        <Container fluid className="scroll-anime lf-anime mobile-post grad-bg">
          <div
            className="bg-layer"
            style={{ backgroundImage: `url(${circlebg})` }}
          />
          <Row>
            <Container>
              <Row>
                <Col
                  xl={{ span: 3, order: 1 }}
                  lg={{ span: 4, order: 1 }}
                  md={{ span: 4, order: 1 }}
                  sm={{ span: 6, order: 2 }}
                  xs={{ span: 6, order: 2 }}
                >
                  <Image src={mobile3} alt="Mobile" />
                </Col>
                <Col
                  xl={{ span: 8, order: 2, offset: 1 }}
                  lg={{ span: 7, order: 2, offset: 1 }}
                  md={{ span: 8, order: 2, offset: 0 }}
                  sm={{ span: 12, order: 1, offset: 0 }}
                  xs={{ span: 12, order: 1, offset: 0 }}
                  className="d-flex flex-column  justify-content-center mb-4"
                >
                  <h2>Swap Crypto Effortlessly</h2>
                  <p>
                    Swap tokens across multiple blockchains—all in a secure,
                    fast, and simple process.
                  </p>
                  <SimpleLink />
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
        {/* Mobile Section */}

        {/* Mobile Section */}
        <Container fluid className="scroll-anime mobile-post tile-bg">
          <div
            className="bg-layer"
            style={{ backgroundImage: `url(${TileLS}), url(${TileR})` }}
          />
          <Row>
            <Container>
              <Row>
                <Col
                  xl="8"
                  lg="7"
                  md="8"
                  sm="12"
                  className="d-flex flex-column  justify-content-center mb-4"
                >
                  <h2>Unite your digital world</h2>
                  <p>
                    Manage assets across multiple blockchains in one intuitive
                    platform, keeping you in complete control.
                  </p>
                  <SimpleLink />
                </Col>
                <Col
                  xl={{ span: 3, offset: 1 }}
                  lg={{ span: 4, offset: 1 }}
                  md={{ span: 4, offset: 0 }}
                  sm={{ span: 6, offset: 6 }}
                  xs={{ span: 6, offset: 6 }}
                >
                  <Image src={mobile4} alt="Mobile" />
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
        {/* Mobile Section */}

        {/* Get Started Panel */}
        <Container fluid className="scroll-anime get-strated-section">
          <Row>
            <Container>
              <Row>
                <Col xl="12" lg="12" md="12">
                  <GetStarted />
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
        {/* Get Started Panel */}

        {/* Mobile Section */}
        <Container fluid className="scroll-anime lf-anime mobile-post tile-bg">
          <div
            className="bg-layer"
            style={{ backgroundImage: `url(${TileL}), url(${TileRS})` }}
          />
          <Row>
            <Container>
              <Row>
                <Col
                  xl={{ span: 3, order: 1 }}
                  lg={{ span: 4, order: 1 }}
                  md={{ span: 4, order: 1 }}
                  sm={{ span: 6, order: 2 }}
                  xs={{ span: 6, order: 2 }}
                  className="d-flex flex-column  justify-content-center"
                >
                  <Image src={mobile5} alt="Mobile" />
                </Col>
                <Col
                  xl={{ span: 8, order: 2, offset: 1 }}
                  lg={{ span: 7, order: 2, offset: 1 }}
                  md={{ span: 8, order: 2, offset: 0 }}
                  sm={{ span: 12, order: 1, offset: 0 }}
                  xs={{ span: 12, order: 1, offset: 0 }}
                  className="d-flex flex-column  justify-content-center mb-4"
                >
                  <h2>FIU Registered</h2>
                  <p>
                    Registered with Financial Intelligence Unit and fully
                    complaint with KYC/AML laws
                  </p>
                  <h5 className="font-weight-bold">Self Custody</h5>
                  <p className="mb-0">
                    Every transaction is executed on blockchain, eliminating all
                    the problems associated with buying from centralized
                    authorities like transparency, having no custody of funds
                    etc.
                  </p>
                  <p>
                    Users can go to Solscan and enter their wallet address and
                    check all the tokens they hold and respective balances
                    providing complete transparency.
                  </p>
                  <h5 className="font-weight-bold">Top Tier Security</h5>
                  <p>
                    Built on the robust Solana blockchain, it ensures
                    lightning-fast transactions and minimal fees, making crypto
                    trading seamless. From major cryptocurrencies to emerging
                    tokens, FirstDex caters to all trading needs while
                    maintaining top-tier security
                  </p>
                  <SimpleLink />
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
        {/* Mobile Section */}

        {/* Accrodian Panel */}
        {/* <Container fluid className="scroll-anime frequntly-panel">
          <Row>
            <Container>
              <Row>
                <Col xl="12" lg="12" md="12">
                  <Frequentlyquestion />
                </Col>
              </Row>
            </Container>
          </Row>
        </Container> */}
        {/* Accrodian Panel */}

        {/* Help Panel */}
        {/* <Container fluid className="Help_Panel scroll-anime">
          <Row>
            <Container>
              <Row>
                <Col
                  xl={{ span: 8, offset: 2 }}
                  lg={{ span: 8, offset: 2 }}
                  md={{ span: 10, offset: 1 }}
                  sm={{ span: 12, offset: 0 }}
                  xs={{ span: 12, offset: 0 }}
                >
                  <h3>Hi, How can we help?</h3>
                  <div className="search-pnl">
                    <Button>
                      <Image src={iconsearch} alt="Search Icon" />
                    </Button>
                    <Form.Control
                      type="text"
                      placeholder="How can we help you?"
                    />
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Dui quis enim quam.
                  </p>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container> */}
        {/* Help Panel */}

        {/* New FirstDex */}
        <Container fluid>
          <Row>
            <div className="new-firstdex-pnl scroll-anime">
              <div
                className="bg-layer"
                style={{ backgroundImage: `url(${Mask})` }}
              />
              <h3>FirstDex – Single solution to all the problems</h3>
              <BtnlistWhite />
            </div>
          </Row>
        </Container>
        {/* New FirstDex */}

        {/* Help Center Panel */}
        <Container fluid id="Help-center" className="scroll-anime Help-center">
          <Row>
            <Container>
              <Row>
                <GetinTouch />
              </Row>
            </Container>
          </Row>
        </Container>
        {/* Help Center Panel */}
      </main>
    </>
  );
}
export default Home;
