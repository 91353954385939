import React from "react";
import { Button, Col, Form, Image } from "react-bootstrap";
import getintouch from "../Images/get-in-touch.png";
function GetinTouch() {
  return (
    <>
      <Col xl="5" lg="6" md="7" sm="12">
        <h4>Help Center</h4>
        <p>Let's Unlock the Future of Crypto Together!</p>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Full Name</Form.Label>
            <Form.Control type="text" placeholder="Full Name" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Your Email</Form.Label>
            <Form.Control type="email" placeholder="Your Email" />
          </Form.Group>
          <Form.Group className="mb-4" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Message</Form.Label>
            <Form.Control as="textarea" rows={7} placeholder="Message" />
          </Form.Group>
          <Button className="submit-btn mb-4" variant="primary" type="submit">
            Send
          </Button>
        </Form>
      </Col>
      <Col
        xl={{ span: 5, offset: 2 }}
        lg={{ span: 5, offset: 1 }}
        md={{ span: 5, offset: 0 }}
        sm={{ span: 8, offset: 0 }}
        xs={{ span: 8, offset: 0 }}
        className="d-flex flex-column  justify-content-center"
      >
        <Image src={getintouch} alt="Get In TOuch" />
      </Col>
    </>
  );
}
export default GetinTouch;
